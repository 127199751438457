input {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 1.2rem;
  margin: 10px 0px;
  padding: 4px 8px;
  transition: box-shadow 0.3s ease-in-out;
  width: 70%;
}

input:focus {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 4px 12px;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 1.2rem;
  margin: 10px 0px;
  padding: 4px 8px;
  width: 70%;
}

@media (max-width: 768px) {
  input {
    margin: 10px auto;
    padding: 8px;
    width: 90%;
  }
}
