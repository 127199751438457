a {
  color: rgba(101, 115, 131, 1);
  font-weight: bold;
  text-decoration: none;
}

.login-card h1 {
  font-family: "Roboto Serif", serif;
}

.login-card h3 {
  color: rgba(101, 115, 131, 1);
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.login-card-wrapper .login-card {
  background-color: rgba(101, 115, 131, 0.2);
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  min-height: 50vh;
  margin: auto;
  padding: 12px;
  text-align: center;
  width: 40%;
}

.login-card-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

/* XS */
@media (max-width: 768px) {
  body {
    background-color: rgba(101, 115, 131, 0.2);
  }

  .login-card-wrapper .login-card {
    background: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    min-height: auto;
    width: 80%;
  }
}

/* S */
@media only screen and (min-width: 768px) {
}

/* MD */
@media only screen and (min-width: 992px) {
}

/* LG */
@media (min-width: 1200px) {
}
