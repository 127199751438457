button {
  background-color: rgba(101, 115, 131, 0.1);
  border: solid 1px rgba(101, 115, 131, 0.2);
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0px;
  padding: 8px 8px;
  transition: box-shadow 0.5s ease-in-out;
  width: 70%;
}

button:active,
button:hover {
  background-color: rgba(101, 115, 131, 0.1);
  box-shadow: rgba(101, 115, 131, 0.4) 0px 0px 20px 5px;
}

.button-as-link {
  background: none;
  border: none !important;
  box-shadow: none;
  display: inline;
  font-size: 1rem;
  width: auto;
}

.default {
  background-color: rgba(101, 115, 131, 1) !important;
  color: #ffffff;
  font-size: 20px;
}

/* XS */
@media (max-width: 768px) {
  button {
    margin: 10px auto;
    padding: 8px;
    width: 90%;
  }

  .default {
    background-color: rgba(101, 115, 131, 1) !important;
    font-size: 20px;
  }
}
